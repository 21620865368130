import React, {createContext} from 'react';

import {
    createVpaas as createVpaasSdk,
    createRecvTransceivers,
    createVpaasSignals,
    getTraceparent,
} from '@pexip/vpaas-sdk';
import type {Roster, RosterEntry} from '@pexip/vpaas-sdk';
import type {MediaController} from '@pexip/media';
import type {TransceiverConfig} from '@pexip/peer-connection';
import {createEventQueue} from '@pexip/peer-connection';
import {createSignal} from '@pexip/signal';
import {StreamQuality} from '@pexip/media-components';
import {stopMediaStream} from '@pexip/media-control';
import type {Participant, RequestStream} from '@pexip/vpaas-api';
import {createApi} from '@pexip/vpaas-api';

import {RECV_AUDIO_COUNT, RECV_VIDEO_COUNT} from '../constants';
import {config} from '../config';
import {logger} from '../logger';

import {mediaController} from './media.context';

export const vpaasSignals = createVpaasSignals();

export const onRemoteStreams = createSignal<MediaStream[]>({
    name: 'mee:onRemoteStreams',
});

export const onPresentation = createSignal<MediaStream | undefined>({
    name: 'mee:onPresentation',
});

const streamQualityToRid = (streamQuality: StreamQuality) => {
    switch (streamQuality) {
        case StreamQuality.High:
            return 'h';
        case StreamQuality.Low:
            return 'l';
        default:
            return 'm';
    }
};

const createMee = (mediaController: MediaController) => {
    const traceparent = getTraceparent();
    const trace = {traceparent};
    const backendApi = createApi();
    const vpaasSdk = createVpaasSdk({
        vpaasSignals,
        config: {trace},
    });

    let myParticipantId: string;
    const roster = new Map<string, RosterEntry>();
    // TODO: this seems unnecessary we just need to have a better structure of the onRemoteStreams signal
    const streamIdtoPId = new Map<string, string>();
    const requestedStreams = new Map<string, TransceiverConfig | undefined>();
    let presentationStream: MediaStream | undefined;

    const emitStreams = () => {
        onRemoteStreams.emit(
            Array.from(requestedStreams.entries()).flatMap(
                ([id, transceiverConfig]) => {
                    const remoteStreams =
                        transceiverConfig?.remoteStreams ?? [];
                    for (const remoteStream of remoteStreams) {
                        streamIdtoPId.set(remoteStream.id, id);
                    }
                    return remoteStreams;
                },
            ),
        );
    };
    const requestStreams = async (pids: Roster) => {
        let shouldUpdate = false;
        for (const id of requestedStreams.keys()) {
            const [pId, nr] = id.split('-');
            if (pId && nr && !pids[pId]?.streams[nr]) {
                requestedStreams.delete(id);
                shouldUpdate = true;
            }
        }

        if (shouldUpdate) {
            emitStreams();
        }

        for (const [producerId, producer] of Object.entries(pids)) {
            for (const [streamId, stream] of Object.entries(producer.streams)) {
                // To simplify layout receive its own presentation
                if (
                    producerId === myParticipantId &&
                    (stream.semantic !== 'presentation' ||
                        (stream.semantic === 'presentation' &&
                            stream.type === 'audio'))
                ) {
                    continue;
                }
                const id = `${producerId}-${streamId}`;
                if (!requestedStreams.has(id)) {
                    // Add streamId to the Map as soon as possible so
                    // we are not running this code again as getting mid is async
                    requestedStreams.set(id, undefined);
                    const preferredRid = streamQualityToRid(
                        config.get('streamQuality'),
                    );
                    const res = await vpaasSdk.requestStream({
                        producer_id: producerId,
                        stream_id: streamId,
                        rid:
                            stream.type === 'video' &&
                            stream.layers?.find(
                                // for testing simulcast we just pick the `medium` stream
                                // when we move this code to the app, user will model this
                                ({rid}) => rid === preferredRid,
                            )
                                ? preferredRid
                                : null,
                        //FIXME: some props should be optional in `RequestStream` type
                    } as RequestStream);

                    const transceiverConfig = vpaasSdk
                        .getTransceiverConfigs()
                        .find(
                            ({transceiver}) =>
                                transceiver?.mid === res.receive_mid,
                        );
                    if (transceiverConfig) {
                        requestedStreams.set(id, transceiverConfig);
                    }

                    emitStreams();
                }
            }
        }
    };

    const refreshStreams = () => {
        for (const [id, transceiverConfig] of requestedStreams.entries()) {
            const [producerId, streamId] = id.split('-');
            const mid = transceiverConfig?.transceiver?.mid;
            if (
                !producerId ||
                typeof streamId === 'undefined' ||
                typeof mid === 'undefined'
            ) {
                continue;
            }
            const stream = roster.get(producerId)?.streams[streamId];
            if (
                !transceiverConfig?.transceiver ||
                !stream ||
                stream.type !== 'video'
            ) {
                continue;
            }
            const preferredRid = streamQualityToRid(
                config.get('streamQuality'),
            );
            void vpaasSdk.requestStream({
                producer_id: producerId,
                stream_id: streamId,
                rid: stream.layers?.find(
                    // for testing simulcast we just pick the `medium` stream
                    // when we move this code to the app, user will model this
                    ({rid}) => rid === preferredRid,
                )
                    ? preferredRid
                    : null,
                receive_mid: mid,
            });
        }
    };

    const connect = async ({
        meetingId,
        abortController,
    }: {
        meetingId: string;
        abortController?: AbortController;
    }) => {
        const _connect = async () => {
            const participants = () =>
                backendApi.participants({
                    apiAddress: `${config.get('apiAddress')}/api`,
                    abortSignal: abortController?.signal,
                    meetingId,
                    headers: {
                        ...trace,
                    },
                });
            const res = await participants();

            const participant = res?.data as Participant & {
                crudAddress: string;
            };
            myParticipantId = participant.id;

            await vpaasSdk.joinMeeting({
                apiAddress: participant.crudAddress,
                participantId: participant.id,
                participantSecret: participant.participant_secret,
                meetingId,
                abortController,
            });

            return vpaasSdk.connect({
                abortController,
                get mediaInits() {
                    const stream = mediaController.media.stream;
                    const [audioTrack] = stream?.getAudioTracks() ?? [];
                    const [videoTrack] = stream?.getVideoTracks() ?? [];
                    const {width, height} = videoTrack?.getSettings() ?? {};
                    logger.debug(
                        {stream, audioTrack, videoTrack, width, height},
                        'Media inits',
                    );
                    return [
                        {
                            content: 'main',
                            direction: 'sendonly',
                            kindOrTrack: audioTrack ?? 'audio',
                            streams: stream && audioTrack ? [stream] : [],
                        } as const,
                        {
                            content: 'main',
                            direction: 'sendonly',
                            kindOrTrack: videoTrack ?? 'video',
                            streams: stream && videoTrack ? [stream] : [],
                            sendEncodings:
                                width && height
                                    ? [
                                          {
                                              rid: 'l',
                                              scaleResolutionDownBy: 4.0,
                                              maxWidth: Math.trunc(width / 4),
                                              maxHeight: Math.trunc(height / 4),
                                          },
                                          {
                                              rid: 'm',
                                              scaleResolutionDownBy: 2.0,
                                              maxWidth: Math.trunc(width / 2),
                                              maxHeight: Math.trunc(height / 2),
                                          },
                                          {
                                              rid: 'h',
                                              maxWidth: width,
                                              maxHeight: height,
                                          },
                                      ]
                                    : undefined,
                        } as const,
                        ...createRecvTransceivers('audio', RECV_AUDIO_COUNT),
                        ...createRecvTransceivers('video', RECV_VIDEO_COUNT),
                    ];
                },
            });
        };
        await _connect();
    };

    const getPresentationStream = async () => {
        const stream = await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: true,
        });
        stream.getVideoTracks().forEach(track => {
            // track.onended syntax doesn't seem to work
            track.addEventListener('ended', () => {
                logger.debug('Track ended. Stop presentation.');
                stopPresenting();
            });
        });
        onPresentation.emit(stream);
        return stream;
    };

    const stopPresentationStream = () => {
        if (presentationStream) {
            stopMediaStream(presentationStream);
            presentationStream = undefined;
            onPresentation.emit();
        }
    };

    const present = async () => {
        stopPresentationStream();
        try {
            presentationStream = await getPresentationStream();
            vpaasSdk.present(presentationStream);
        } catch (error) {
            logger.error({error}, 'Presenting failed');
        }
    };

    const stopPresenting = () => {
        stopPresentationStream();

        vpaasSdk.stopPresenting();
    };

    const streamRequestsQueue = createEventQueue((pids: Roster) => {
        void requestStreams(pids);
    });
    const releaseStreamRequestsBuffer = () => {
        streamRequestsQueue.buffering = false;
        const streamRequestFlushed = streamRequestsQueue.flush();
        logger.debug(
            {streamRequests: streamRequestFlushed},
            'release buffered stream requests',
        );
    };

    const _detachApiSignals = [
        vpaasSignals.onRosterUpdate.add(rosterEvent => {
            for (const [id, rosterEntry] of Object.entries(rosterEvent)) {
                roster.set(id, rosterEntry);
            }
            streamRequestsQueue.enqueue(rosterEvent);
        }),
        vpaasSignals.onRemoteStreams.add(() => {
            emitStreams();
        }),
        vpaasSignals.onError.add(error => {
            logger.error({error}, 'Received error');
        }),
        vpaasSignals.onReconnecting.add(() => {
            streamRequestsQueue.buffering = true;
            requestedStreams.clear();
        }),
        vpaasSignals.onMediaOffer.add(() => {
            releaseStreamRequestsBuffer();
        }),
    ];

    return {
        connect,
        disconnect: () => {
            mediaController.media.release().catch((error: unknown) => {
                logger.error({error}, `GUM cleanup failed`);
            });
            vpaasSdk.disconnect();
            stopPresenting();
        },
        setStream: vpaasSdk.setStream,
        getPId(sId: string) {
            const id = streamIdtoPId.get(sId);
            const [producerId, streamId] = (id ?? '').split('-');
            if (!producerId || !streamId) {
                return [undefined, undefined] as const;
            }
            const rosterEntry = roster.get(producerId);
            const stream = rosterEntry?.streams[streamId];
            return [producerId, stream] as const;
        },
        refreshStreams,
        present,
        stopPresenting,
        get myParticipantId() {
            return myParticipantId;
        },
    };
};

const mee = createMee(mediaController);

export const MeeContext = createContext<
    ReturnType<typeof createMee> | undefined
>(undefined);

export const MeeProvider: React.FC<React.PropsWithChildren> = ({children}) => (
    <MeeContext.Provider value={mee}>{children}</MeeContext.Provider>
);
