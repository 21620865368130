import {Backoff} from '@pexip/utils';
import type {TransceiverConfig} from '@pexip/peer-connection';
import {createSignal} from '@pexip/signal';
import type {
    ForbiddenError,
    InternalServerError,
    VpaasWebsocketMessageTypesUnion,
    NotModifiedError,
    RosterUpdate,
    ServiceUnavailableError,
    UnauthorizedError,
    UnknownError,
    Error as ResponseError,
    MediaOffer,
} from '@pexip/vpaas-api';
import {BadRequestError, GoneError, NotFoundError} from '@pexip/vpaas-api';
import {createSocketSignals as createSS} from '@pexip/socket-manager';

import {BACKOFF_BASE_SETTINGS, MAX_RECONNECT_ATTEMPTS} from './constants';
import {logger} from './logger';
import type {VpaasError, VpaasSignals} from './types';

export const createVpaasSignals = () =>
    ({
        onRosterUpdate: createSignal<RosterUpdate['participants']>({
            name: 'mee:onRosterUpdate',
        }),
        onMediaOffer: createSignal<MediaOffer>({
            name: 'mee:onMediaOffer',
        }),
        onReconnecting: createSignal({
            name: 'mee:reconnecting',
        }),
        onReconnected: createSignal({
            name: 'mee:reconnecting',
        }),
        onRemoteStreams: createSignal<TransceiverConfig>({
            name: 'mee:onRemoteStreams',
        }),
        onError: createSignal<VpaasError>({
            name: 'mee:onError',
        }),
    }) satisfies VpaasSignals;

export const createSocketSignals = () =>
    createSS<VpaasWebsocketMessageTypesUnion>();

export const createRecvTransceivers = (
    kind: 'audio' | 'video',
    length: number,
    content?: string,
) =>
    Array.from(
        {length},
        () =>
            ({
                direction: 'recvonly',
                kindOrTrack: kind,
                content,
            }) as const,
    );

export const sleep = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));

const ANONYMOUS_FN = 'Anonymous';

const getName = <T>(observer: () => Promise<T>) => {
    return observer.name || ANONYMOUS_FN;
};

export const retriable = async <T>(
    fn: () => Promise<T>,
    backoff = new Backoff(BACKOFF_BASE_SETTINGS),
    retries = MAX_RECONNECT_ATTEMPTS,
): Promise<T> => {
    try {
        return await fn();
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
            logger.warn({error}, `${getName(fn)} aborted`);
            throw error;
        }

        if (retries === 0) {
            logger.error({error}, `${getName(fn)} failed`);
            throw error;
        }

        if (error instanceof BadRequestError) {
            logger.warn(
                {error},
                `${getName(fn)} has bad request. No point of retrying`,
            );
            throw error;
        }

        if (error instanceof NotFoundError) {
            logger.warn(
                {error},
                `${getName(fn)} not found. No point of retrying`,
            );
            throw error;
        }

        if (error instanceof GoneError) {
            logger.warn({error}, `${getName(fn)} gone. No point of retrying`);
            throw error;
        }

        logger.error({error, retries}, `retrying ${getName(fn)}`);
        await sleep(backoff.duration());
        return await retriable(fn, backoff, retries - 1);
    }
};

export const isSendConfig = (config: TransceiverConfig) =>
    config.direction === 'sendonly';
export const isInactiveConfig = (config: TransceiverConfig) =>
    config.direction === 'inactive';
export const isRecvConfig = (config: TransceiverConfig) =>
    config.direction === 'recvonly';
export const isMainConfig = (config: TransceiverConfig) =>
    config.content === 'main';
export const isPresoConfig = (config: TransceiverConfig) =>
    config.content === 'slides';
export const isAudioConfig = (config: TransceiverConfig) =>
    config.kind === 'audio';
export const isVideoConfig = (config: TransceiverConfig) =>
    config.kind === 'video';
export const isMainSendConfig = (config: TransceiverConfig) =>
    [isMainConfig, isSendConfig].every(fn => fn(config));
export const isPresoInactiveConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isInactiveConfig].every(fn => fn(config));
export const isPresoRecvConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isRecvConfig].every(fn => fn(config));
export const isPresoSendConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isSendConfig].every(fn => fn(config));
export const isPresoVideo = (config: TransceiverConfig) =>
    [isPresoConfig, isVideoConfig].every(fn => fn(config));

export class WebsocketError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'WebsocketError';
    }
}

export class ResourceUnavailableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ResourceUnavailableError';
    }
}

export class MeetingFullError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'MeetingFullError';
    }
}

export {
    BadRequestError,
    UnauthorizedError,
    ForbiddenError,
    GoneError,
    NotFoundError,
    ServiceUnavailableError,
    InternalServerError,
    NotModifiedError,
    UnknownError,
};

const TRACE_CHARS = '0123456789abcdef';
const getRandomString = (length: number, chars = TRACE_CHARS) =>
    Array.from({length}, (_, i) => i).reduce(
        acc => acc + chars[(Math.random() * chars.length) | 0],
        '',
    );

export const getTraceparent = () =>
    `00-${getRandomString(32)}-${getRandomString(16)}-01`;

export const isResponseError = (error: unknown): error is ResponseError => {
    if (
        error &&
        typeof error === 'object' &&
        'type' in error &&
        error.type === 'error' &&
        'error_type' in error &&
        'error_message' in error
    ) {
        return true;
    }
    return false;
};
