import React from 'react';

import {FullscreenLayout, Section} from '@pexip/components';

export const Main: React.FC = () => {
    return (
        <FullscreenLayout>
            <Section
                title="Sorry, you can't create a meeting here"
                subtitle="Please use the Slack integration"
            />
        </FullscreenLayout>
    );
};
