import {logger} from './logger';

const el = document.querySelector('#mee-config');
interface DeploymentConfig {
    API_ADDRESS?: string;
    ENV?: string;
}

const deploymentConfig: DeploymentConfig = (() => {
    try {
        return el ? (JSON.parse(el.innerHTML) as DeploymentConfig) : {};
    } catch (error: unknown) {
        logger.error(error, 'failed to parse deploymentConfig');
        return {};
    }
})();

const env = (import.meta as unknown as {env: Record<string, string>}).env;
export const API_ADDRESS =
    deploymentConfig.API_ADDRESS ?? env.VITE_API_ADDRESS ?? '';
export const ENV = deploymentConfig.ENV ?? 'local';
export const NAMESPACE = 'mee';
// Until we get proper authentication this is hardcoded
export const USER_ID = 'Bob';
export const RECV_AUDIO_COUNT = 14;
export const RECV_VIDEO_COUNT = 14;
export const DEFAULT_WIDTH = 1280;
export const DEFAULT_HEIGHT = 720;

/**
 * Version
 */
export const VERSION = process.env.BUILD_HASH ?? 'BUILD_HASH missing';
export const DIRTY = process.env.BUILD_DIRTY ? '*' : '';
export const APP_VERSION = process.env.APP_VERSION;
