import type {AddNewCandidate} from '@pexip/vpaas-api';
import type {MediaInit} from '@pexip/peer-connection';
import {
    createPCSignals,
    createEventQueue,
    createMainPeerConnection,
} from '@pexip/peer-connection';
import type {Detach} from '@pexip/signal';

import {logger} from './logger';
import type {
    VpaasProps,
    VpaasSignals,
    SocketManager,
    SocketSignals,
} from './types';
import {isMainConfig, isPresoVideo} from './utils';

const toIceCandidate = (ice: RTCIceCandidate): AddNewCandidate => ({
    candidate: ice.candidate,
    mid: ice.sdpMid ?? '0',
    ufrag: ice.usernameFragment ?? '',
    pwd: '', // this should be optional in the `AddNewCandidate` type
});

export const createCall = ({
    meeSignals,
    socketSignals,
    socket,
    mediaInits,
    abortController,
    props,
}: {
    meeSignals: VpaasSignals;
    socketSignals: SocketSignals;
    socket: SocketManager;
    mediaInits: MediaInit[];
    abortController?: AbortController;
    props?: VpaasProps;
}) => {
    const pcSignals = createPCSignals([
        'onIceCandidate',
        'onRemoteStreams',
        'onConnectionStateChange',
    ]);
    const pc = createMainPeerConnection(pcSignals, {
        mediaInits,
        rtcConfig: {
            bundlePolicy: 'max-bundle',
        },
    });
    window.pexDebug = {...window.pexDebug, pc};

    const handleAbort = () => {
        if (pc.peer.connectionState === 'connecting') {
            close();
        }
    };

    abortController?.signal.addEventListener('abort', handleAbort, {
        once: true,
    });

    let detachSocketSignals: Detach[] = [];
    let detachPCSignals: Detach[] = [
        pcSignals.onConnectionStateChange.add(connectionState => {
            if (['connected', 'failed'].includes(connectionState)) {
                abortController?.signal.removeEventListener(
                    'abort',
                    handleAbort,
                );
            }
        }),
        pcSignals.onOffer.add(({sdp}) => {
            if (!sdp) {
                return;
            }

            socket.send({
                type: 'media_offer',
                sdp,
                trace: props?.trace,
            });
        }),

        pcSignals.onRemoteStreams.add(streams => {
            logger.debug({streams}, 'Remote streams received');
            meeSignals.onRemoteStreams.emit(streams);
        }),
    ];

    if (props?.sendCandidates) {
        const sendCandidate = (candidate: RTCIceCandidate | null) => {
            if (!candidate) {
                logger.debug('End of candidates');
                return;
            }

            socket.send({
                type: 'add_new_candidate',
                ...toIceCandidate(candidate),
                trace: props?.trace,
            });
        };

        const outgoingICECandidateQueue = createEventQueue(sendCandidate);

        const releaseOutGoingCandidateBuffer = () => {
            outgoingICECandidateQueue.buffering = false;
            const candidatesFlushed = outgoingICECandidateQueue.flush();
            logger.debug(
                {outgoingCandidates: candidatesFlushed},
                'release buffered outgoing candidates',
            );
        };

        detachSocketSignals.push(
            socketSignals.onMessage.add(msg => {
                // Wait for this event as backend is not ready to receive candidates before
                if (msg.type === 'media_offer') {
                    releaseOutGoingCandidateBuffer();
                }
            }),
        );

        detachPCSignals.push(
            pcSignals.onIceCandidate.add(outgoingICECandidateQueue.enqueue),
        );
    }

    detachSocketSignals.push(
        socketSignals.onMessage.add(msg => {
            switch (msg.type) {
                case 'media_offer':
                    meeSignals.onMediaOffer.emit(msg);
                    pcSignals.onReceiveAnswer.emit(
                        new RTCSessionDescription({
                            sdp: msg.sdp,
                            type: 'answer',
                        }),
                    );
                    break;
                case 'roster_update':
                    meeSignals.onRosterUpdate.emit(msg.participants);
                    break;

                default:
                    // TODO
                    break;
            }
        }),
    );

    const setStream = (stream: MediaStream) => {
        // for (const config of pc.getTransceiverConfigs()) {
        //     if (isMainConfig(config) && config.direction === 'sendonly') {
        //         const [track] =
        //             config.kind === 'video'
        //                 ? stream.getVideoTracks()
        //                 : stream.getAudioTracks();
        //         await config.syncTransceiver(pc.peer, {
        //             streams: stream ? [stream] : [],
        //             track: track ?? null,
        //             direction: 'sendonly',
        //         });
        //     }
        // }
        pcSignals.onOfferRequired.emit({
            stream,
            target: pc
                .getTransceiverConfigs()
                .flatMap(config =>
                    isMainConfig(config) && config.direction === 'sendonly'
                        ? [[config]]
                        : [],
                ),
        });
    };

    const present = (presentationStream: MediaStream) => {
        try {
            const [audioTrack] = presentationStream?.getAudioTracks() ?? [];
            const [videoTrack] = presentationStream?.getVideoTracks() ?? [];
            logger.debug(
                {
                    presentationStream,
                    audioTrack,
                    videoTrack,
                },
                'Present',
            );

            /**
             * Current way of starting presentation is to create dynamic
             * m-line when start presenting. There is no way to start with inactive line
             * because answer doesnt mirror `content` property so there is no way to
             * sync transceivers after negotiation.
             */
            pc.addConfig(pc.peer, {
                content: 'slides',
                direction: 'sendonly',
                kindOrTrack: videoTrack ?? 'video',
                streams:
                    presentationStream && videoTrack
                        ? [presentationStream]
                        : [],
            } as const);
        } catch (error) {
            logger.error({error}, 'Presenting failed');
        }
    };

    const stopPresenting = () => {
        pc.getTransceiverConfigs().forEach(config => {
            if (
                isPresoVideo(config) &&
                config?.transceiver?.direction !== 'stopped'
            ) {
                config.transceiver?.stop();
            }
        });
    };

    const cleanup = () => {
        detachSocketSignals.forEach(detach => detach());
        detachSocketSignals = [];

        detachPCSignals.forEach(detach => detach());
        detachPCSignals = [];
    };

    const close = () => {
        cleanup();
        if (pc.connectionState === 'closed') {
            return;
        }
        pc.close();
    };

    pcSignals.onOfferRequired.emit();

    return {
        get pc() {
            return pc;
        },
        setStream,
        present,
        stopPresenting,
        close,
    };
};

export type Call = ReturnType<typeof createCall>;
