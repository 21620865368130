export enum ContentType {
    JSON = 'application/json',
    FORM_URLENCODED = 'application/x-www-form-urlencoded',
}

export enum HEADER {
    ACCEPT = 'Accept',
    CONTENT_TYPE = 'Content-Type',
    AUTHORIZATION = 'Authorization',
}

export {setLogger} from './logger.js';
